import * as React from "react";
import "./navbar.css";
import 'animate.css';
import { Button } from "@mui/material";
import { Link } from "gatsby-link";

export interface Menu {
    name:string;
    path?:string;
    targetBlank?: boolean;
    links?: any[];
}

type Props = {
    menuItems: Menu[]
}
  
const Submenu: React.FC<Props> = (props) => {
    return (
        <div className="navsubmenu">
            {props.menuItems?.map((menuItem, index) =>
                <div className="subMenuItem" key={index}>
                    {menuItem.path ?                    
                        menuItem.links ? (
                            <React.Fragment key={index}>
                              <Button className="nav-button">{menuItem.name}</Button>
                              <Submenu menuItems={menuItem.links} />
                            </React.Fragment>
                        ) :
                            menuItem.targetBlank ?
                            <a href={menuItem.path} target="_blank" >
                                <Button className="nav-button">{ menuItem.name }</Button>
                            </a>
                            :
                            <Link to={menuItem.path} style={{textDecoration: "none"}} key={index}>
                                <Button className="nav-button">{ menuItem.name }</Button>
                            </Link>
                        :
                        <></>
                    }
                </div>
            )}
        </div>
    )
}

export default Submenu
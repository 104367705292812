import { Container, Grid } from "@mui/material";
import * as React from "react";
import FooterDireccion from "./direccionFooter/direccionFooter";
import facebookIcon from "../images/svg/white/facebook.svg";
import instagramIcon from "../images/svg/white/instagram.svg";
import xIcon from "../images/svg/white/xlogo.svg";
import tiktokIcon from "../images/svg/white/tiktok.svg";
import { Link } from "gatsby";
import { useSingleType } from "../hooks/use-single-type";
import LazyLoad from 'react-lazyload';

type Props = {
    clickOnDirection: () => void
}

const Copyright: React.FC<any> = ({text = ''}) => {
    if (!text) return <></>
    
    let year = new Date().getFullYear().toString();    
    text = text.replace('$year$', year)
    return <>{text}</>;
}

const Footer: React.FC<Props> = (props) => {
  const [footer, setFooter] = React.useState<any>();

    useSingleType({
        name: "footer",
        onSuccess(result) {
            setFooter(result);
        },
        onError(error) {
            console.log(error);            
        },
        onComplete() {
        },
      });

    let icons:any = {
        "facebook": facebookIcon,
        "instagram": instagramIcon,
        "x": xIcon,
        "tiktok": tiktokIcon,
    }

    return (
        <LazyLoad height={208} once>
            <footer style={{ textAlign: "center", padding: 32}}>
                <Container maxWidth="xl">
                    <Grid item className={"divFooter"}>
                        <Grid item xs={12} lg={9} className={"divtxtFooter"} style={{marginBottom: 9}} >
                            {footer?.links?.map((link:any) => (
                                <Link key={link.id} className={"itemsFooter"} style={{ width: "fit-content", alignSelf: "center" }} to={link.path}>{link.name}</Link>
                            ))}
                        </Grid>
                        <Grid item xs={12} lg={3} className={`${"divImgFooter"}`}>
                            {footer?.social_networks?.map((social:any) => (
                                <span key={social.id} className={""} style={{ textAlign: "right", marginLeft: 15}}>
                                    <a href={social.url} target={"_blank"} rel={"noreferrer"} style={{ display: "flex", marginBottom: "0" }}>
                                        <img className={"icon"} alt="Facebook" src={icons[social.web]} style={{marginBottom: "0", marginRight: "10px" }} />{social.name}
                                    </a>
                                </span>
                            ))}
                        </Grid>
                    </Grid>
                    <FooterDireccion footerData={footer} clickOnDirection={props.clickOnDirection}/>
                    <p style={{ fontSize: 18 }}><Copyright text={footer?.copyright_text} /></p>
                </Container>
            </footer>
        </LazyLoad>
    )
}

export default Footer;

import React, { useEffect } from 'react';
import { isProductionDomain } from '../utils/helperFunctions';

interface ScriptItem {
  id: number;
  item_type: string;
  code_or_src: string;
  script_html_id: string;
  type: string;
  async: boolean;
  defer: boolean;
  location: string;
}

interface Script {
  id: number;
  name: string;
  production_only: boolean;
  items: ScriptItem[];  
}

const useDynamicScripts = () => {
  useEffect(() => {
    const url = `${process.env.API_STRAPI}scripts?populate=*`;
    const dynamicElements: (HTMLScriptElement | HTMLElement)[] = [];

    fetch(url, {
      headers: {
        Authorization: `Bearer ${process.env.STRAPI_API_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((response) => response.data)
      .then((scripts) => {
        if (scripts) {
          scripts.forEach((script:Script) => {
            if (script.production_only && !isProductionDomain()) {
              return;
            }
            script.items.forEach((item:ScriptItem) => {
              let element: HTMLElement | null = null;

              if (item.item_type === "script") {
                // Maneja etiquetas <script>
                element = document.createElement("script");

                if (item.type === "src") {
                  (element as HTMLScriptElement).src = item.code_or_src;
                } else if (item.type === "code") {
                  element.textContent = item.code_or_src;
                }

                // Agrega atributos opcionales
                if (item.async) (element as HTMLScriptElement).async = true;
                if (item.defer) (element as HTMLScriptElement).defer = true;
              } else if (item.item_type === "noscript") {
                // Maneja etiquetas <noscript>
                element = document.createElement("noscript");
                element.innerHTML = item.code_or_src;
              } else if (item.item_type === "html") {
                // Maneja bloques HTML genéricos
                const container = document.createElement("div");
                container.innerHTML = item.code_or_src;
                element = container.firstChild as HTMLElement | null;
              }

              // Agrega el elemento al DOM en la ubicación definida
              if (element) {

                if(item.script_html_id){
                  element.id = item.script_html_id;
                }
                
                if (item.location === "head") {
                  document.head.appendChild(element);
                } else if (item.location === "body") {
                  debugger
                  document.body.appendChild(element);
                }
                if (element instanceof HTMLElement) {
                  dynamicElements.push(element); // Asegura el tipo correcto
                }
              }
            });
          });
        }
      })
      .catch((reason) => {
        console.error("Error conectando con el servidor Strapi: " + reason);
      });

    // Limpieza al desmontar o actualizar
    return () => {
      dynamicElements.forEach((element) => {
        element.remove();
      });
    };
  }, []);
};



export default useDynamicScripts;